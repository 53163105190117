
body,
html {
  background-color: #f1f1f1;
}

/* body {
  max-width: 1500px;
  width: 90%;
  margin: 0 auto;
} */
